import {Component, OnInit, ViewChild} from '@angular/core';
import {EditorComponent, InputState, UPCInputState} from 'pm-components';
import {
  AttachmentsCardPermissions,
  Candidate,
  CandidateError,
  CandidateHelper,
  CandidateProduct,
  CandidateProductError,
  CandidateValidatorType,
  CaseDetailsCardPermissions,
  CostCardPermissions,
  CostLinkCardPermissions,
  DisplayReadyUnitCardPermissions,
  ImportedProductCardPermissions,
  InnerPackDetailsCardPermissions,
  MasterPackDetailsCardPermissions,
  MerchandisingInfoCardPermissions,
  OmiRemarksCardPermissions,
  Product,
  SupplierDetailsCardPermissions,
  Upc
} from 'pm-models';
import {ActivatedRoute, Router} from '@angular/router';
import {CostService} from '../../service/cost.service';
import {SupplierCasePackService} from '../../service/supplier-case-pack.service';
import {LookupService} from '../../service/lookup.service';
import {CandidateService} from '../../service/candidate.service';
import {GrowlService} from '../../growl/growl.service';
import {UUID} from 'angular2-uuid';
import {CandidateUtilService} from '../../service/candidate-util.service';
import {ProductService} from '../../service/product.service';
import {calculateCheckDigit} from '../../shared/upc.utils';
import {ItemWeightTypeService} from '../../service/item-weight-type.service';
import {PiaAdditionalCasePackStepperComponent} from '../pia-additional-case-pack-stepper/pia-additional-case-pack-stepper.component';

@Component({
  selector: 'app-pia-additional-case-pack-details',
  templateUrl: './pia-additional-case-pack-details.component.html',
  styleUrls: ['./pia-additional-case-pack-details.component.scss']
})
export class PiaAdditionalCasePackDetailsComponent implements OnInit {

  @ViewChild(EditorComponent) pmEditor;

  public BOTH_ITEM_TYPE = 'BOTH';
  public DSD_ITEM_TYPE = 'DSD';
  public WAREHOUSE_ITEM_TYPE = 'ITMCD';
  public KEY_RETAIL = 'Key Retail';
  public IMAGE_BASE_URL = 'https://images.heb.com/is/image/HEBGrocery/';
  public isViewingPage = true;
  private taskSubscription$: any;
  public candidateError: CandidateError;
  public candidateProductError: CandidateProductError;
  public upcState: UPCInputState;
  public costLinkState: InputState;
  public productImageUrl: string = null;
  public DEFAULT_NO_PRODUCT_IMAGE = '../../../assets/images/no_image.png';
  public currentCandidateProductIndex = 1;
  private searchedCandidateProductIndex = 0;
  public productData: Product;
  public primaryUpc: Upc;
  public productInfoString: string;
  public displayingDrawer = false;
  public canNavigate = true;
  public departmentId = undefined;
  public scanCodeCheckDigit: number;
  public merchandiseTypes: any;
  public commodities: any;
  public unitsOfMeasures: any;
  public packageTypes: any;
  public containerSizes: any;
  public incoTermsList: any;
  public costOwners: any = [];
  supplierDetailsCardPermissions: SupplierDetailsCardPermissions;
  additionalCasePackBasicsCardPermissions: CaseDetailsCardPermissions;

  constructor(private route: ActivatedRoute, private router: Router, private costService: CostService,
              public supplierCasePackService: SupplierCasePackService, private lookupService: LookupService,
              private candidateService: CandidateService, private growlService: GrowlService, private productService: ProductService,
              private candidateUtilService: CandidateUtilService, private itemWeightTypeService: ItemWeightTypeService) {
  }

  getAdditionalCasePackBasicsCardPermissions(): CaseDetailsCardPermissions {
    const permissions: CaseDetailsCardPermissions = {
      isReadOnly: false,
      itemWeightType: {
        isReadOnly: false,
        isDisabled: !this.isTiedToCatchOrVariableWeightBuyer()
      },
      dsdSwitch: {
        isDisabled: true
      },
      warehouseSwitch: {
        isDisabled: true
      },
      buyer: {
        isHidden: true
      },
      dealOffered: {
        isHidden: true
      }
    };
    return permissions;
  }

  readonly masterPackDetailsCardPermissions: MasterPackDetailsCardPermissions = {
    isReadOnly: false
  };
  readonly innerPackDetailsCardPermissions: InnerPackDetailsCardPermissions = {
    isReadOnly: false
  };
  readonly displayReadyUnitCardPermissions: DisplayReadyUnitCardPermissions = {
    isReadOnly: false
  };
  readonly costLinkCardPermissions: CostLinkCardPermissions = {
    isReadOnly: false
  };
  readonly costCardPermissions: CostCardPermissions = {
    isReadOnly: false
  };
  readonly attachmentsCardPermissions: AttachmentsCardPermissions = {
    isReadOnly: false
  };
  readonly merchandisingInfoCardPermissions: MerchandisingInfoCardPermissions = {
    isReadOnly: false,
    merchandiseType: {
      isHidden: true
    },
    commodity: {
      isHidden: true
    },
    subCommodity: {
      isHidden: true
    },
    season: {
      isHidden: false
    },
    seasonYear: {
      isHidden: false
    },
    pssDepartment: {
      isHidden: true
    }
  };
  readonly omiRemarksPermissions: OmiRemarksCardPermissions = {
    isReadOnly: false,
  };

  readonly importProductCardPermissions: ImportedProductCardPermissions = {
    isReadOnly: false
  };

  getSupplierDetails(): SupplierDetailsCardPermissions {
    const permissions: SupplierDetailsCardPermissions = {
      isReadOnly: false,
      brand: {
        isDisabled: true
      },
      subBrand: {
        isHidden: true
      },
      vendor: {
        isHidden: true
      },
      lane: {
        isHidden: true
      }
    };
    return permissions;
  }

  ngOnInit() {
    this.lookupService.findAllUnitsOfMeasures().subscribe(unitsOfMeasures => {
      this.unitsOfMeasures = unitsOfMeasures;
    });
    this.lookupService.findAllPackageTypes().subscribe(packageTypes => {
      this.packageTypes = packageTypes;
    });

    this.taskSubscription$ = this.route.queryParamMap.subscribe(params => {
      const taskId = CandidateUtilService.getTaskIdFromTaskAndTaskId(this.supplierCasePackService.getTaskId(),
        this.supplierCasePackService.getTask());

      if (this.candidateUtilService.shouldRefetchCandidateByTaskParams(params, taskId)) {
        this.supplierCasePackService.resetService();
        this.supplierCasePackService.setCandidateByUrlParameters(params).subscribe((candidate: Candidate) => {
          if (candidate.candidateType === Candidate.ADDITIONAL_CASE_PACK) {
            this.supplierCasePackService.setOriginalAndCurrentCandidate(candidate);
            this.initializeData();
          } else {
            this.router.navigate(['/tasks']);
          }
        });
      } else {
        this.candidateUtilService.isValidCandidateAndTaskData(taskId, this.supplierCasePackService.getCandidate()).subscribe(
          (isValid) => {
            if (isValid) {
              this.initializeData();
            } else {
              this.supplierCasePackService.resetService();
              this.router.navigate(['/tasks']);
            }
          });
      }
    });
  }

  onClickNext() {
    this.jumpTo('/piaCasePackWarehouseComponent');
  }

  jumpTo(url) {
    this.supplierCasePackService.setCandidate(this.supplierCasePackService.candidate);
    this.canNavigate = false;

    this.candidateService.validateCandidate(this.supplierCasePackService.candidate,
      [CandidateValidatorType.PIA_ADDITIONAL_CASE_PACK_DETAILS_VALIDATOR]).subscribe(data => {
      this.candidateError = this.supplierCasePackService.setAdditionalCasePackDetailsError(new CandidateError());
      this.supplierCasePackService.saveCandidateAndNavigate(url, false);
    }, (error) => {
      this.supplierCasePackService.scrollToTop();
      // set the errors on the page
      if (error.error?.candidateErrors?.hasErrors) {
        this.candidateError = this.supplierCasePackService.setAdditionalCasePackDetailsError(error.error.candidateErrors);
        this.candidateProductError = this.supplierCasePackService.getCurrentCandidateProductError(this.candidateError);
      }
      this.supplierCasePackService.saveCandidateAndNavigate(url, false);
    });
  }

  onClickBack() {
    this.supplierCasePackService.setCandidate(this.supplierCasePackService.candidate);
    this.supplierCasePackService.saveCandidateAndNavigate('/piaSetupCasePackComponent', false);
  }

  onClose() {
    this.supplierCasePackService.setCandidate(this.supplierCasePackService.candidate);
    this.supplierCasePackService.saveCandidateAndNavigate('/tasks', false);
  }

  validateCostLink() {
    this.costLinkState = InputState.loading;
    this.supplierCasePackService.candidate.costLinkFromServer = undefined;
    this.candidateError.costLink = undefined;
    this.candidateService.validateCandidate(this.supplierCasePackService.candidate,
      [CandidateValidatorType.COST_LINK_VALIDATOR]).subscribe((validatedCandidate) => {
      this.costLinkState = InputState.valid;
      this.costService.updateCostLinkRelatedValuesFromValidatedCandidate(this.supplierCasePackService.getCandidate(), validatedCandidate);
    }, (error) => {
      this.costLinkState = InputState.invalid;
      if (error.error.candidateErrors) {
        this.costLinkState = InputState.invalid;
        this.candidateError.costLink = error.error.candidateErrors.costLink;
      } else {
        this.growlService.addError(error.message);
      }
    });
  }

  setProductInfoString() {
    this.productInfoString = '' + this.primaryUpc.size +
      ' | Retail: '  +  this.costService.toCurrency(this.primaryUpc.retailPrice);
  }

  showExistingCasePacks() {
    this.displayingDrawer = true;
    this.pmEditor.openDrawer();
  }

  collapse() {
    this.displayingDrawer = false;
    this.pmEditor.closeDrawer();
  }

  onClickCasePack(casePack) {
    casePack.isViewing = !casePack.isViewing;
  }

  /**
   * Returns the current candidate product.
   */
  public getCurrentCandidateProduct(): CandidateProduct {
    if (this.supplierCasePackService.candidate.candidateProducts.length > 1) {
      return this.supplierCasePackService.candidate.candidateProducts[this.currentCandidateProductIndex];
    } else {
      const candidateProduct = new CandidateProduct();
      candidateProduct.id = UUID.UUID();
      candidateProduct.candidateProductType = CandidateProduct.NEW_ITEM;
      return candidateProduct;
    }
  }

  initializeData() {
    this.candidateError = this.supplierCasePackService.getAdditionalCasePackDetailsError();
    this.candidateProductError = new CandidateProductError();
    this.setProductData();
    this.findMerchandiseTypesAndSetDefault();
  }

  setProductData() {
    this.candidateError = this.supplierCasePackService.getSetupAdditionalCasePackError();
    this.candidateProductError = this.supplierCasePackService.getCurrentCandidateProductError(this.candidateError);
    this.productImageUrl = null;
    this.lookupService.findAllContainerSizes().subscribe(containerSizes => {
      this.containerSizes = containerSizes;
    });
    this.lookupService.findAllIncoTerms().subscribe(incoTermsList => {
      this.incoTermsList = incoTermsList;
    });
    if (this.supplierCasePackService.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType ===
      CandidateProduct.SEARCHED_UPC) {
      this.lookupService.getProductByUpcAndApNumbers(
        this.supplierCasePackService.candidate.candidateProducts[this.searchedCandidateProductIndex].upc,
        []).subscribe((productData) => {
        this.productData = productData;
        this.supplierCasePackService.candidate.productId = productData.productId;
        this.supplierCasePackService.getCandidate().candidateProducts[this.currentCandidateProductIndex].description = productData.productDescription;
        this.setPrimaryUpcAndRetail();
        this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.productData);
        this.setDepartmentAndCommodity();
        this.getCheckDigit();
        this.setCostOwners();
      });
    } else if (this.supplierCasePackService.candidate.candidateProducts[this.searchedCandidateProductIndex].candidateProductType ===
      CandidateProduct.SEARCHED_ITEM) {
      this.lookupService.getProductByItemCodeAndApNumbers(
        this.supplierCasePackService.candidate.candidateProducts[this.searchedCandidateProductIndex].itemCode, [])
        .subscribe((productData) => {
          this.productData = productData;
          this.supplierCasePackService.candidate.candidateProducts[this.searchedCandidateProductIndex].upc = productData.primaryScanCodeId;
          this.supplierCasePackService.getCandidate().candidateProducts[this.currentCandidateProductIndex].description = productData.productDescription;
          this.setPrimaryUpcAndRetail();
          this.productImageUrl = this.candidateUtilService.getProductImageUrl(this.productData);
          this.setDepartmentAndCommodity();
          this.getCheckDigit();
          this.setCostOwners();
        });
    }
  }

  /**
   * Sets the cost owners based on the BrandId from the parent product.
   */
  public setCostOwners() {
    this.supplierCasePackService.candidate.brand = {brandId: this.productData.brand.brandId,
      displayName: this.productData.brand.description + '[' + this.productData.brand.brandId + ']',
      description: this.productData.brand.description};
    this.lookupService.findBrandsById(
      [this.productData.brand.brandId]).subscribe(brandList => {
      if (brandList && brandList.length > 0) {
        this.costOwners = brandList[0].costOwners;
        this.supplierDetailsCardPermissions = this.getSupplierDetails();
      }
    });
  }

  setPrimaryUpcAndRetail() {
    const primaryUpcCode = this.productData.primaryScanCodeId;

    if (this.productData.items && this.productData.items.length > 0) {
      this.setPrimaryUpcAndRetailFromItem(primaryUpcCode);
    } else {
      this.setPrimaryUpcAndRetailFromDsdItem();
    }
  }

  setPrimaryUpcAndRetailFromDsdItem() {
    this.productService.getUpcBySearchedValue(this.supplierCasePackService.getCandidate().candidateProducts[0],
      this.productData).subscribe(
      (upc) => {
        this.primaryUpc = upc;
        this.setProductInfoString();
        this.setRetail();
      });
  }

  setPrimaryUpcAndRetailFromItem(primaryUpcCode) {
    // find upc equal to that of the primary, if not found, set first upc of first item.
    for (let x = 0; x < this.productData.items.length; x++) {
      if (this.productData.items[x].innerUpcs && this.productData.items[x].innerUpcs.length > 0) {
        for (let y = 0; y < this.productData.items[x].innerUpcs.length; y++) {
          if (this.productData.items[x].innerUpcs[y].upc?.scanCodeId === primaryUpcCode) {
            this.primaryUpc = this.productData.items[x].innerUpcs[y].upc;
            this.setProductInfoString();
            this.setRetail();
            return;
          }
        }
      } else if (this.productData.items[x].containedUpc && this.productData.items[x].containedUpc?.upc &&
        this.productData.items[x].containedUpc.upc.scanCodeId === primaryUpcCode) {
        this.primaryUpc = this.productData.items[x].containedUpc.upc;
        this.setRetail();
        this.setProductInfoString();
        return;
      }
    }
    if (this.productData.items[0].containedUpc) {
      this.primaryUpc = this.productData.items[0].containedUpc.upc;
    } else {
      this.primaryUpc = this.productData.items[0].innerUpcs[0].upc;
    }
    this.setRetail();
    this.setProductInfoString();
  }

  setRetail() {
    this.supplierCasePackService.candidate.suggestedRetailPrice = this.primaryUpc.retailPrice;
    this.supplierCasePackService.candidate.suggestedXFor = 1;
  }

  setDepartmentAndCommodity() {
    if (this.productData.items && this.productData.items.length > 0) {

      for (let x = 0; x < this.productData.items.length; x++) {
        if (this.productData.items[x].hierarchy &&
          !this.productData.items[x].mrt &&
          this.productData.items[x].hierarchy.commodity &&
          this.productData.items[x].hierarchy.commodity.itemClass &&
          this.productData.items[x].hierarchy.commodity.itemClass.subDepartment &&
          this.productData.items[x].hierarchy.commodity.itemClass.subDepartment.department) {
          this.departmentId = +this.productData.items[x].hierarchy.commodity.itemClass.subDepartment.department.departmentId;
          this.supplierCasePackService.candidate.commodity = { commodityId: this.productData.items[x].hierarchy.commodity.commodityId,
            commodityName: this.productData.items[x].hierarchy.commodity.description,
            departmentId: this.productData.items[x].hierarchy.commodity.itemClass.subDepartment.department.departmentId};
          this.supplierCasePackService.candidate.subCommodity = {
            subCommodityId: this.productData.items[x].hierarchy.subCommodityId.toString(),
            subCommodityName: this.productData.items[x].hierarchy.description, subCommodityTaxCode: null,
            subCommodityFoodStamp: null, taxableVertexTaxCategory: null, nonTaxableVertexTaxCategory: null
          };
          this.supplierCasePackService.candidate.buyer = { buyerId: this.productData.items[x].hierarchy.commodity.bdm.bdmId,
            buyerName: this.productData.items[x].hierarchy.commodity.bdm.fullName,
            userId: this.productData.items[x].hierarchy.commodity.bdm.onePassId};
          break;
        }
      }
      this.additionalCasePackBasicsCardPermissions = this.getAdditionalCasePackBasicsCardPermissions();
    } else {
      for (let x = 0; x < this.productData.dsdItems.length; x++) {
        if (this.productData.dsdItems[x].hierarchy &&
          !this.productData.dsdItems[x].mrt &&
          this.productData.dsdItems[x].hierarchy.commodity &&
          this.productData.dsdItems[x].hierarchy.commodity.itemClass &&
          this.productData.dsdItems[x].hierarchy.commodity.itemClass.subDepartment &&
          this.productData.dsdItems[x].hierarchy.commodity.itemClass.subDepartment.department) {
          this.departmentId = +this.productData.dsdItems[x].hierarchy.commodity.itemClass.subDepartment.department.departmentId;
          this.supplierCasePackService.candidate.commodity = { commodityId: this.productData.dsdItems[x].hierarchy.commodity.commodityId,
            commodityName: this.productData.dsdItems[x].hierarchy.commodity.description,
            departmentId: this.productData.dsdItems[x].hierarchy.commodity.itemClass.subDepartment.department.departmentId};
          this.supplierCasePackService.candidate.subCommodity = {
            subCommodityId: this.productData.dsdItems[x].hierarchy.subCommodityId.toString(),
            subCommodityName: this.productData.dsdItems[x].hierarchy.description, subCommodityTaxCode: null,
            subCommodityFoodStamp: null, nonTaxableVertexTaxCategory: null, taxableVertexTaxCategory: null
          };
          this.supplierCasePackService.candidate.buyer = { buyerId: this.productData.dsdItems[x].hierarchy.commodity.bdm.bdmId,
            buyerName: this.productData.dsdItems[x].hierarchy.commodity.bdm.fullName,
            userId: this.productData.dsdItems[x].hierarchy.commodity.bdm.onePassId};
          break;
        }
      }
      this.additionalCasePackBasicsCardPermissions = this.getAdditionalCasePackBasicsCardPermissions();
    }
  }
  validateUpc() {
    this.upcState = UPCInputState.loading;
    this.candidateService.validateCandidate(this.supplierCasePackService.candidate,
      [CandidateValidatorType.CASE_UPC_VALIDATOR]).subscribe(() => {
      this.upcState = UPCInputState.valid;
      this.candidateProductError.caseUpc = undefined;
    }, (error) => {
      // if there's an error, and it's an instance of candidate error model, update the candidate product's
      // upc/checkdigit errors. If there's not a candidate product or this candidate product in the error model,
      // add the whole candidate product error model.
      if (error.error.candidateErrors) {

        const candidateProductId = this.supplierCasePackService.candidate.candidateProducts[this.currentCandidateProductIndex].id;
        const candidateErrors: CandidateError = error.error.candidateErrors;
        let candidateProductError: CandidateProductError =
          candidateErrors.candidateProductErrors[candidateProductId];

        if (this.candidateError.candidateProductErrors.size === 0 ||
          !this.candidateError.candidateProductErrors[
            this.supplierCasePackService.candidate.candidateProducts[this.currentCandidateProductIndex].id]) {
          candidateProductError = candidateErrors.candidateProductErrors[candidateProductId];
          this.candidateError.candidateProductErrors.set(candidateProductId,
            candidateProductError);
          this.candidateProductError = candidateProductError;
          this.candidateError.itemWeightType = candidateErrors.itemWeightType;
          if (this.candidateProductError.caseUpc ||  this.candidateProductError.caseUpcCheckDigit) {
            this.upcState = UPCInputState.invalid;
          } else {
            this.supplierCasePackService.setAdditionalCasePackDetailsError(candidateErrors);
            this.upcState = UPCInputState.valid;
          }
        } else {
          this.candidateProductError.caseUpc = candidateProductError.caseUpc;
          this.candidateProductError.caseUpcCheckDigit = candidateProductError.caseUpcCheckDigit;
          this.candidateError.itemWeightType = candidateErrors.itemWeightType;
          if (this.candidateProductError.caseUpc ||  this.candidateProductError.caseUpcCheckDigit) {
            this.upcState = UPCInputState.invalid;
          } else {
            this.supplierCasePackService.setAdditionalCasePackDetailsError(candidateErrors);
            this.upcState = UPCInputState.valid;
          }
        }
      } else {
        this.upcState = UPCInputState.invalid;
        this.growlService.addError(error.message);
      }
    });
  }

  onInnerPackChange() {
    if (!this.supplierCasePackService.candidate.innerPackSelected) {
      this.candidateProductError.caseUpc = null;
      this.upcState = UPCInputState.none;
    }
    if (this.supplierCasePackService.getCandidate() && this.supplierCasePackService.getCandidate().costLinked
      && this.supplierCasePackService.getCandidate().costLink) {
      this.validateCostLink();
    }
  }

  innerPackQuantityChange() {
    if (this.candidateProductError && this.candidateProductError.caseUpc) {
      this.candidateProductError.caseUpc = null;
      this.upcState = UPCInputState.none;
    }
  }

  getCheckDigit() {
    if (this.productData.primaryScanCodeId) {
      this.scanCodeCheckDigit = calculateCheckDigit(this.productData.primaryScanCodeId);
    } else {
      this.scanCodeCheckDigit = null;
    }
  }

  /**
   * Finds the merchandiseTypes, and sets the default value for sellable.
   */
  findMerchandiseTypesAndSetDefault() {
    const isSellable = this.supplierCasePackService.getOriginalCandidate().productType === 'SELLABLE';
    const itemType = CandidateUtilService.getItemType(this.supplierCasePackService.getOriginalCandidate());
    this.lookupService.findAllMerchandiseTypes(itemType, isSellable).subscribe(merchandiseTypes => {
      this.merchandiseTypes = merchandiseTypes;
      // Default to basic for sellable (if there's no merchandise type).
      if (!this.supplierCasePackService.getOriginalCandidate().merchandiseType && isSellable) {
        for (let x = 0; x < this.merchandiseTypes.length; x++) {
          if (this.merchandiseTypes[x].description.trim() === 'Basic') {
            this.supplierCasePackService.getOriginalCandidate().merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            this.supplierCasePackService.getCandidate().merchandiseType = {
              merchandiseTypeCode: this.merchandiseTypes[x].merchandiseTypeCode,
              description: this.merchandiseTypes[x].description
            };
            break;
          }
        }
      }
    });
    if (!isSellable) {
      this.setNonSellableRetailValues();
    }
  }

  /**
   * Sets non sellable default values.
   */
  private setNonSellableRetailValues() {
    this.supplierCasePackService.getOriginalCandidate().retailXFor = 1;
    this.supplierCasePackService.getOriginalCandidate().retailPrice = '0.00';
    this.supplierCasePackService.getOriginalCandidate().retailType = this.KEY_RETAIL;
  }

  private setDefaultVertexTaxCategory(candidate: Candidate) {
    // initialize it to the default category if it's not set
    if (!candidate.vertexTaxCategory) {
      candidate.vertexTaxCategory = CandidateHelper.getVertexTaxCategory(candidate);
    }
  }

  /**
   * Trigger cost link validation on master pack change.
   * @param event
   */
  onMasterPackChange(event) {
    if (this.supplierCasePackService.getCandidate() && this.supplierCasePackService.getCandidate().costLinked
      && this.supplierCasePackService.getCandidate().costLink) {
      this.validateCostLink();
    }
  }

  isTiedToCatchOrVariableWeightBuyer(): boolean {
    if (this.supplierCasePackService.getCandidate().commodity && this.supplierCasePackService.getCandidate().commodity?.departmentId) {
      return this.itemWeightTypeService.getItemWeightTypeDepartments().includes(this.supplierCasePackService.getCandidate().commodity.departmentId);
    } else {
      return false;
    }
  }


  onClickStepper(stepperItem) {
    if (!this.canNavigate) {
      return;
    }
    switch (stepperItem.text) {
      case PiaAdditionalCasePackStepperComponent.UPC_ITEM_CODE: {
        this.onClickBack();
        break;
      }
      case PiaAdditionalCasePackStepperComponent.CASE_PACK: {
        break;
      }
      case PiaAdditionalCasePackStepperComponent.WAREHOUSE_SELECTION: {
        this.onClickNext();
        break;
      }
      case PiaAdditionalCasePackStepperComponent.EXTENDED_ATTRIBUTES: {
        this.jumpTo('/piaCasePackExtendedAttributes');
        break;
      }
    }
  }
}
